import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/singin',
    name: 'singin',
    component: () => import(/* webpackChunkName: "about" */ '../views/SingInView.vue')
  },
  {
    path: '/countries',
    name: 'countries',
    component: () => import(/* webpackChunkName: "about" */ '../views/CountriesView.vue')
  },
  {
    path: '/regions',
    name: 'regions',
    component: () => import(/* webpackChunkName: "about" */ '../views/RegionsView.vue')
  },
  {
    path: '/hotels',
    name: 'hotels',
    component: () => import(/* webpackChunkName: "about" */ '../views/HotelsView.vue')
  },
  {
    path: '/hotelsauto',
    name: 'hotelsauto',
    component: () => import(/* webpackChunkName: "about" */ '../views/HotelAutoView.vue')
  },
   {
    path: '/hotelswar',
    name: 'hotelswar',
    component: () => import(/* webpackChunkName: "about" */ '../views/HotelWars.vue')
  },
  {
    path: '/mappreport',
    name: 'mappreport',
    component: () => import(/* webpackChunkName: "about" */ '../views/MappReport.vue')
  },
  {
    path: '/hoteledit/:hotelid',
    name: 'hoteledit',
    component: () => import(/* webpackChunkName: "about" */ '../views/HotelEditView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/SamoPriceAnalysis',
    name: 'SamoPriceAnalysis',
    component: () => import(/* webpackChunkName: "about" */ '../views/SamoPriceAnalysis.vue')
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProfileView.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
