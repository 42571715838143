import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Multiselect from 'vue-multiselect'

import VueWysiwyg from '@mycure/vue-wysiwyg';

Vue.use(VueWysiwyg);

Vue.config.productionTip = false


window.url_api = process.env.NODE_ENV === 'production' ? 'https://recms.r-express.ru/' : 'https://localhost:44328/'
//window.url_api =  'https://recms.r-express.ru/api/'

console.log('NODE_ENV'+process.env.NODE_ENV );


Date.prototype.format = function(format = 'yyyy-mm-dd') {
  const replaces = {
      yyyy: this.getFullYear(),
      mm: ('0'+(this.getMonth() + 1)).slice(-2),
      dd: ('0'+this.getDate()).slice(-2),
      hh: ('0'+this.getHours()).slice(-2),
      MM: ('0'+this.getMinutes()).slice(-2),
      ss: ('0'+this.getSeconds()).slice(-2)
  };
  let result = format;
  for(const replace in replaces){
      result = result.replace(replace,replaces[replace]);
  }
  return result;
};

Array.prototype.contains = function(v) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] === v) return true;
  }
  return false;
};

Array.prototype.unique = function() {
  var arr = [];
  for (var i = 0; i < this.length; i++) {
    if (!arr.contains(this[i])) {
      arr.push(this[i]);
    }
  }
  return arr;
}


axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  //console.log('axios.interceptors.request  before| '+localStorage.getItem('token'))
  config.headers.Authorization = localStorage.getItem('token');
  //config.headers.common['Userlogin'] = localStorage.getItem('Userlogin')

  //console.log('axios.interceptors.request  before')
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});


axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status == 401) {
      //console.log('error 401')

      //console.log(window.location)
      //console.log(window.location.hash)
      
      window.url_return = window.location.hash;
      localStorage.setItem('token', 'XXX')
      router.push('singin').catch(() => {})

     // router.push('singin').catch(() => {})
      //console.log('error 401 end')

    }
    throw new Error('Invalid token detected')
  }
);



Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
  // register globally
Vue.component('multi-select-combo', Multiselect)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
