import axios from 'axios'

export default {
  actions: {
    async sa_searchpriceanalysis({ commit }, filter) {
      return new Promise((resolve, reject) => {
        -// /api/SamoPrice/SearchPriceAnalysis
          commit('sm_searchpriceanalysiswaite', true)

        axios.post(window.url_api + 'api/SamoPrice/SearchPriceAnalysis', filter, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            // console.log(response.data.result)
            commit('sm_searchpriceanalysis', response.data)
            commit('sm_searchpriceanalysisfields', response.data)


            resolve(response.data)
          })
          .catch(error => {
            console.log(error.response)
            commit('sm_searchpriceanalysiswaite', false)
            reject(new Error("Не удалось авторизоваться"));
          })
          .finally(() => {
            // commit('sm_countrysearchsLoading', false)
            //  commit('sm_waitcountrysearch', false)
            commit('sm_searchpriceanalysiswaite', false)
          })
      })
    },

    async sa_samoCountries({ commit }) {
      return new Promise(() => {

        commit('sm_waitsamoCountries', true)

        axios.get(window.url_api + 'api/SamoPrice/Countries'

          , {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            console.log(response.data.result)
            commit('sm_samoCountries', response.data.result)
          })
          .catch(error => {
            console.log(error.response)
            commit('sm_waitsamoCountries', false)
          })
          .finally(() => {

            commit('sm_waitsamoCountries', false)
          })
      })
    },

    async sa_samoCities({ commit }, filter) {
      return new Promise((resolve, reject) => {

        commit('sm_waitsamoCities', true)

        axios.post(window.url_api + 'api/SamoPrice/Cities',
       filter,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            console.log(response.data.result)

            commit('sm_samoCities', response.data.result)
            resolve(response.data.result)
          })
          .catch(error => {
            console.log(error.response)
            commit('sm_waitsamoCities', false)
            reject(new Error("Ошибка"));
          })
          .finally(() => {

            commit('sm_waitsamoCities', false)
          })
      })
    },

    async sa_samoRegions({ commit },countryId) {
      return new Promise((resolve, reject) => {

        commit('sm_waitsamoRegions', true)

        axios.get(window.url_api + 'api/SamoPrice/Regions?countryId=' + countryId

          , {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            console.log(response.data.result)
            commit('sm_samoRegions', response.data.result)
            resolve(response.data.result)
          })
          .catch(error => {
            console.log(error.response)
            commit('sm_waitsamoRegions', false)
            reject(new Error("Ошибка"));
          })
          .finally(() => {

            commit('sm_waitsamoRegions', false)
          })
      })
    },

    async sa_roomGroups({ commit }) {
      return new Promise((resolve, reject) => {

        commit('sm_waitRoomGroups', true)

        axios.get(window.url_api + 'api/SamoPrice/RoomGroups'
          , {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            console.log(response.data.result)

            commit('sm_RoomGroups', response.data.result)
            resolve(response.data.result)
          })
          .catch(error => {
            console.log(error.response)
            commit('sm_waitRoomGroups', false)
            reject(new Error("Ошибка"));
          })
          .finally(() => {

            commit('sm_waitRoomGroups', false)
          })
      })
    },

    async sa_samoMeals({ commit }) {
      return new Promise((resolve, reject) => {

        commit('sm_waitsamoMeals', true)

        axios.get(window.url_api + 'api/SamoPrice/MealsGroup'
          , {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            console.log(response.data.result)

            commit('sm_samoMeals', response.data.result)
            resolve(response.data.result)
          })
          .catch(error => {
            console.log(error.response)
            commit('sm_waitsamoMeals', false)
            reject(new Error("Ошибка"));
          })
          .finally(() => {

            commit('sm_waitsamoMeals', false)
          })
      })
    },

    async sa_samoStarGroups({ commit }) {
      return new Promise(() => {

        commit('sm_waitsamoStarGroups', true)

        axios.get(window.url_api + 'api/SamoPrice/Stargroup'

          , {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            console.log(response.data.result)
            commit('sm_samoStarGroups', response.data.result)
          })
          .catch(error => {
            console.log(error.response)
            commit('sm_waitsamoStarGroups', false)
          })
          .finally(() => {

            commit('sm_waitsamoStarGroups', false)
          })
      })
    },

    async sa_SamoHotels({ commit },filter) {
      return new Promise((resolve, reject) => {

          commit('sm_waitSamoHotels', true)

          axios.post(window.url_api + 'api/SamoPrice/Hotels',
          filter
          , {
              headers: {
                  'Content-Type': 'application/json'
              }
          })
              .then(response => {
                  console.log(response.data.result)
      
                  commit('sm_SamoHotels', response.data.result)
      resolve(response.data.result)
              })
              .catch(error => {
                  console.log(error.response)
                  commit('sm_waitSamoHotels', false)
      reject(new Error("Ошибка"));
              })
              .finally(() => {
          
                  commit('sm_waitSamoHotels', false)
              })
      })
  },




  },
  mutations: {

    sm_searchpriceanalysiswaite(state, searchpriceanalysiswait) {
      state.searchpriceanalysiswait = searchpriceanalysiswait
    },

    sm_searchpriceanalysis(state, pricedata) {
      state.pricedata = pricedata.result
    },


    sm_searchpriceanalysisfields(state, pricedata) {
      console.log(pricedata)

      state.resultsamoFields = [];
      state.resultsamoItems = [];

      state.resultsamoFields.push({ key: 'hotel', label: 'Отель', });
      state.resultsamoFields.push({ key: 'partner', label: 'Партнер', });
      state.resultsamoFields.push({ key: 'spo', label: 'СПО', });

      /* pricedata.result.columnDates.forEach(element => {
         state.resultsamoFields.push({ key: element.replace(".", '_'), label: element, });
       });
     */

      for (let index = 0; index < pricedata.result.columnDates.length; ++index) {
        state.resultsamoFields.push({ key: 'col' + index, label: pricedata.result.columnDates[index], });
      }

      var hotelLast = null;
      var colorRow = false;
      pricedata.result.samoPriceAnalysisGroups.forEach(hotel => {

        hotel.samoPriceAnalysisGroupRows.forEach(pr_spo => {

          var item = { hotel: hotel.hotelName, partner: pr_spo.partnerName, spo: { name: pr_spo.spoName, note: pr_spo.spoNote } };

          if (hotelLast == null)
            hotelLast = hotel.hotelName;
          else if (hotelLast == hotel.hotelName)
            item.hotel = "";
          else
            hotelLast = hotel.hotelName;


          //наполнить яейками с датами/ценами
          for (let index = 0; index < pricedata.result.columnDates.length; ++index) {
            // item[pricedata.result.columnDates[index].replace(".", '_')] = pr_spo.cells[index];
            item['col' + index] = pr_spo.cells[index];
            console.log('int' + item);
          }

          if(colorRow)
            item._rowVariant = 'light';
            else
            item._rowVariant = 'secondary';

          state.resultsamoItems.push(item);
         

        });
        colorRow = !colorRow;
        
      });




    },


    sm_samoCountries(state, resultsamoCountries) {
      state.resultsamoCountries = resultsamoCountries
    },

    sm_waitsamoCountries(state, waitsamoCountries) {
      state.waitsamoCountries = waitsamoCountries
    },

    sm_samoCities(state, resultsamoCities) {
      state.resultsamoCities = resultsamoCities
    },

    sm_waitsamoCities(state, waitsamoCities) {
      state.waitsamoCities = waitsamoCities
    },


    sm_RoomGroups(state, resultRoomGroups) {
      state.resultRoomGroups = resultRoomGroups
    },

    sm_waitRoomGroups(state, waitRoomGroups) {
      state.waitRoomGroups = waitRoomGroups
    },

    sm_samoMeals(state, resultsamoMeals) {
      state.resultsamoMeals = resultsamoMeals
    },

    sm_waitsamoMeals(state, waitsamoMeals) {
      state.waitsamoMeals = waitsamoMeals
    },

    sm_samoStarGroups(state, resultsamoStarGroups) {
      state.resultsamoStarGroups = resultsamoStarGroups
    },

    sm_waitsamoStarGroups(state, waitsamoStarGroups) {
      state.waitsamoStarGroups = waitsamoStarGroups
    },

    sm_samoRegions(state, resultsamoRegions) {
      state.resultsamoRegions = resultsamoRegions
    },

    sm_waitsamoRegions(state, waitsamoRegions) {
      state.waitsamoRegions = waitsamoRegions
    },

    sm_SamoHotels(state, resultSamoHotels) {
      state.resultSamoHotels = resultSamoHotels
  },

  sm_waitSamoHotels(state, waitSamoHotels) {
      state.waitSamoHotels = waitSamoHotels
  },

  },
  state: {

    searchpriceanalysiswait: false,
    pricedata: {},
    resultsamoCountries: [],
    waitsamoCountries: false,
    resultsamoCities: [],
    waitsamoCities: false,
    resultRoomGroups: [],
    waitRoomGroups: false,

    resultsamoMeals: [],
    waitsamoMeals: false,

    resultsamoStarGroups: [],
    waitsamoStarGroups: false,

    resultsamoFields: [],
    resultsamoItems: [],

    resultsamoRegions: [],
    waitsamoRegions: false,

    resultSamoHotels: [],
    waitSamoHotels: false,

  },
  getters: {
    getsearchpriceanalysiswait: state => state.searchpriceanalysiswait,
    getresultsearchpriceanalysis: state => state.pricedata,

    getresultsamoCountries: state => state.resultsamoCountries,
    getwaitsamoCountries: state => state.waitwaitsamoCountries,

    getresultsamoCities: state => state.resultsamoCities,
    getwaitsamoCities: state => state.waitsamoCities,

    getresultRoomGroups: state => state.resultRoomGroups,
    getwaitRoomGroups: state => state.waitRoomGroups,

    getresultsamoMeals: state => state.resultsamoMeals,
    getwaitsamoMeals: state => state.waitsamoMeals,

    getresultsamoStarGroups: state => state.resultsamoStarGroups,
    getwaitsamoStarGroups: state => state.waitwaitsamoStarGroups,

    getresultsamoFields: state => state.resultsamoFields,
    getresultsamoItems: state => state.resultsamoItems,

    getresultsamoRegions: state => state.resultsamoRegions,
    getwaitsamoRegions: state => state.waitwaitsamoRegions,

    getresultSamoHotels: state => state.resultSamoHotels,
        getwaitSamoHotels: state => state.waitSamoHotels,

  }
}
