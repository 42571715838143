import axios from 'axios'

export default {
  actions: {
    async sa_connect({ commit }, user) {
      return new Promise((resolve, reject) => {

        commit('sm_connectwaite', true)

        axios.post(window.url_api + 'api/Auth/Connect', user, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            console.log(response.data.result)
            commit('sm_connect', response.data.result)
            localStorage.setItem('token', response.data.result.token)
            resolve(response.data.result)
          })
          .catch(error => {
            console.log(error.response)
            commit('sm_connectwaite', false)
            reject(new Error("Не удалось авторизоваться"));
          })
          .finally(() => {
            // commit('sm_countrysearchsLoading', false)
            //  commit('sm_waitcountrysearch', false)
            commit('sm_connectwaite', false)
          })
      })
    },

    async sa_connectchangepass({ commit }, user) {
      return new Promise((resolve) => {
        console.log("cntCHa"+ user)
        axios.post(window.url_api + 'api/Auth/Connect', user, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            commit('sm_connectwaite', false)
            resolve(response.data.result)
          });
          

        
      })
    },


    async sa_syncfilter({ commit }) {
      return new Promise((resolve, reject) => {

        commit('sm_connectwaite', true)

        axios.get(window.url_api + 'api/Auth/SyncFilterData', {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            console.log(response.data.result)
            commit('sm_connect', response.data.result)

            resolve(response.data.result)
          })
          .catch(error => {
            console.log(error.response)

            reject(new Error("Не удалось авторизоваться"));
          })
          .finally(() => {

          })
      })
    }


  },
  mutations: {

    sm_connectwaite(state, connectwait) {
      state.connectwait = connectwait
    },
    sm_connect(state, userinfo) {
      console.log("sm_connect");
      console.log(userinfo);
      state.userinfo = userinfo
      state.actionIds =userinfo.actionIds;
    },


  },
  state: {

    connectwait: false,
    userinfo: null,
    actionIds:[-1]

  },
  getters: {
    getconnectwait: state => state.connectwait,
    getuserinfo: state => state.userinfo,
    getuseractionids: state => state.actionIds,

  }
}
