import axios from 'axios'

export default {
    actions: {
        async sa_hotelsuppliersearch({ commit }, filter) {
            return new Promise(() => {

                commit('sm_waithotelsuppliersearch', true)

                axios.post(window.url_api + 'api/Hotel/HotelSupplierSearch',
                    filter
                    , {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => {
                        console.log(response.data.result)
                        commit('sm_hotelsuppliersearch', response)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waithotelsuppliersearch', false)
                    })
                    .finally(() => {

                        commit('sm_waithotelsuppliersearch', false)
                    })
            })
        },

        async sa_hotelssearch({ commit }, filter) {
            return new Promise((resolve, reject) => {

                commit('sm_waithotelsearch', true)

                axios.post(window.url_api + 'api/Hotel/hotelSearch',
                    filter
                    , {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => {
                        console.log(response.data.result)
                        commit('sm_hotelsearch', response)
                        resolve(response);
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waithotelsearch', false)
                        reject(new Error("Ошибка "+ error.response.data.message));
                    })
                    .finally(() => {

                        commit('sm_waithotelsearch', false)
                    })
            })
        },

        async sa_mappingHotels({ commit }, filter) {
            return new Promise((resolve, reject) => {

                commit('sm_waitmappingHotels', true)
                console.log(filter);
                axios.post(window.url_api + 'api/Hotel/MappingHotels',
                    filter

                    , {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => {
                        console.log(response.data.result)
                        commit('sm_mappingHotels', response.data.result)
                        resolve(response.data)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitmappingHotels', false)
                        reject(error.response)
                    })
                    .finally(() => {

                        commit('sm_waitmappingHotels', false)
                    })
            })
        },

        async sa_categories({ commit }) {
            return new Promise(() => {

                commit('sm_waitCategories', true)

                axios.get(window.url_api + 'api/Hotel/Categories',
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => {
                        console.log(response.data.result)
                        commit('sm_Categories', response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitCategories', false)
                    })
                    .finally(() => {

                        commit('sm_waitCategories', false)
                    })
            })
        },

        async sa_Suppliers({ commit }) {
            return new Promise(() => {

                commit('sm_waitSuppliers', true)

                axios.get(window.url_api + 'api/Hotel/Suppliers',
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => {
                        console.log(response.data.result)
                        commit('sm_Suppliers', response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitSuppliers', false)
                    })
                    .finally(() => {

                        commit('sm_waitSuppliers', false)
                    })
            })
        },

        async sa_HotelAction({ commit }, request) {
            return new Promise((resolve, reject) => {

                commit('sm_waitHotelAction', true)

                axios.post(window.url_api + 'api/Hotel/HotelAction',

                    request

                    , {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => {
                        console.log(response.data.result)

                        commit('sm_HotelAction', response.data.result)
                        resolve(response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitHotelAction', false)
                        reject(new Error("Ошибка"));
                    })
                    .finally(() => {

                        commit('sm_waitHotelAction', false)
                    })
            })
        },

        async sa_CreateParentHotel({ commit }, hotelSuppId) {
            return new Promise((resolve, reject) => {

                commit('sm_waitCreateParentHotel', true)

                axios.get(window.url_api + 'api/Hotel/CreateParentHotel?hotelId=' + hotelSuppId,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => {
                        console.log(response.data.result)

                        commit('sm_CreateParentHotel', response.data.result)
                        resolve(response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitCreateParentHotel', false)
                        reject(new Error("Ошибка"));
                    })
                    .finally(() => {

                        commit('sm_waitCreateParentHotel', false)
                    })
            })
        },

        async sa_UnmappingHotelSupplier({ commit }, request ) {
            return new Promise((resolve, reject) => {

                commit('sm_waitUnmappingHotelSupplier', true)

                axios.get(window.url_api + 'api/Hotel/UnmappingHotelSupplier?hotelId=' + request.hotelId+'&parentId='+request.parentId,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => {
                        console.log(response.data.result)

                        commit('sm_UnmappingHotelSupplier', response.data.result)
                        resolve(response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitUnmappingHotelSupplier', false)
                        reject(new Error("Ошибка"));
                    })
                    .finally(() => {

                        commit('sm_waitUnmappingHotelSupplier', false)
                    })
            })
        },

        async sa_HotelSupplierAutoSearch({ commit },filter) {
            return new Promise((resolve, reject) => {

                commit('sm_waitHotelSupplierAutoSearch', true)

                axios.post(window.url_api + 'api/Hotel/HotelSupplierAutoSearch',
                filter
                
                , {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response.data.result)
						
                        commit('sm_HotelSupplierAutoSearch', response)
						resolve(response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitHotelSupplierAutoSearch', false)
						reject(new Error("Ошибка"));
                    })
                    .finally(() => {
                
                        commit('sm_waitHotelSupplierAutoSearch', false)
                    })
            })
        },

        async sa_MappingHotelsMass({ commit },rq) {
            return new Promise((resolve, reject) => {

                commit('sm_waitMappingHotelsMass', true)

                axios.post(window.url_api + 'api/Hotel/MappingHotelsMass',
                rq                
                , {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                    
                        commit('sm_MappingHotelsMass', response.data.result)
						resolve(response.data)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitMappingHotelsMass', false)
						reject(new Error("Ошибка"));
                    })
                    .finally(() => {
                
                        commit('sm_waitMappingHotelsMass', false)
                    })
            })
        },

        async sa_HotelDetail({ commit },hotelid) {
            return new Promise((resolve, reject) => {

                commit('sm_waitHotelDetail', true)

                axios.get(window.url_api + 'api/Hotel/HotelDetail?hotelId='+hotelid,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response.data.result)
						
                        commit('sm_HotelDetail', response.data.result)
						resolve(response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitHotelDetail', false)
						reject(new Error("Ошибка"));
                    })
                    .finally(() => {
                
                        commit('sm_waitHotelDetail', false)
                    })
            })
        },

        async sa_HotelDetailSave({ commit },hotelData) {
            return new Promise((resolve, reject) => {

                commit('sm_waitHotelDetailSave', true)

                axios.post(window.url_api + 'api/Hotel/HotelDetailSave',
                
                    hotelData
                
                
                , {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response.data)
						
                        commit('sm_HotelDetailSave', response.data)
						resolve(response.data)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitHotelDetailSave', false)
						reject(new Error("Ошибка"));
                    })
                    .finally(() => {
                
                        commit('sm_waitHotelDetailSave', false)
                    })
            })
        },

        async sa_HistoryObject({ commit },filter) {
            return new Promise((resolve, reject) => {

                commit('sm_waitHistoryObject', true)

                axios.post(window.url_api + 'api/Hotel/HistoryObject',
                filter
                , {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response.data.result)
						
                        commit('sm_HistoryObject', response.data.result)
						resolve(response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitHistoryObject', false)
						reject(new Error("Ошибка"));
                    })
                    .finally(() => {
                
                        commit('sm_waitHistoryObject', false)
                    })
            })
        },
        
        async sa_HotelImageAdd({ commit }, request) {
            return new Promise((resolve, reject) => {
      
              commit('sm_waitHotelImageAdd', true)
      
              axios.get(window.url_api + 'api/Hotel/HotelImageAdd?hotelId='+request.hotelId+'&urlImg='+request.imgUrl
      
                , {
                  headers: {
                    'Content-Type': 'application/json'
                  }
                })
                .then(response => {
                  console.log(response.data.result)
                  commit('sm_HotelImageAdd', response.data.result)
                  resolve(response.data.result)
                })
                .catch(error => {
                  console.log(error.response)
                  commit('sm_waitHotelImageAdd', false)
                  reject(error);
                })
                .finally(() => {
      
                  commit('sm_waitHotelImageAdd', false)
                })
            })
        },

          
   async sa_HotelImageSetMain({ commit },request) {
      return new Promise((resolve, reject) => {

        commit('sm_waitHotelImageSetMain', true)

        axios.get(window.url_api + 'api/Hotel/HotelImageSetMain?hotelId='+request.hotelId+'&urlImg='+request.imgUrl

          , {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            console.log(response.data.result)
            commit('sm_HotelImageSetMain', response.data.result)
			resolve(response.data.result)
          })
          .catch(error => {
            console.log(error.response)
            commit('sm_waitHotelImageSetMain', false)
			reject(error);
          })
          .finally(() => {

            commit('sm_waitHotelImageSetMain', false)
          })
      })
    },

    
   async sa_HotelImageRemove({ commit },request) {
    return new Promise((resolve, reject) => {

      commit('sm_waitHotelImageSetMain', false)

      axios.get(window.url_api + 'api/Hotel/HotelImageRemove?hotelId='+request.hotelId+'&urlImg='+request.imgUrl

        , {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          console.log(response.data.result)
         // commit('sm_HotelImageRemove', response.data.result)
          resolve(response.data.result)
        })
        .catch(error => {
          console.log(error.response)
         // commit('sm_waitHotelImageRemove', false)
          reject(error);
        })
        .finally(() => {

         // commit('sm_waitHotelImageRemove', false)
        })
    })
  },

  async sa_HotelFactDetail({ commit },hotelId) {

    console.log('sa_HotelFactDetail'+hotelId);
    return new Promise((resolve, reject) => {

      commit('sm_waitHotelFactDetail', true)

      axios.get(window.url_api + 'api/Hotel/HotelFactDetail?hotelId='+hotelId

        , {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          console.log(response.data.result)
          commit('sm_HotelFactDetail', response.data.result)
          resolve(response.data.result)
        })
        .catch(error => {
          console.log(error.response)
          commit('sm_waitHotelFactDetail', false)
          reject(error);
        })
        .finally(() => {

          commit('sm_waitHotelFactDetail', false)
        })
    })
  },

  async sa_HotelFactDetailSave({ commit },rq) {
    return new Promise((resolve, reject) => {

        commit('sm_waitHotelFactDetailSave', true)

        axios.post(window.url_api + 'api/Hotel/HotelFactDetailSave',
        rq
        , {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log(response.data.result)
                
                commit('sm_HotelFactDetailSave', response.data.result)
                resolve(response.data)
            })
            .catch(error => {
                console.log(error.response)
                commit('sm_waitHotelFactDetailSave', false)
                reject(new Error("Ошибка"));
            })
            .finally(() => {
        
                commit('sm_waitHotelFactDetailSave', false)
            })
    })
},

async sa_HotelMappInfo({ commit },filter) {
    return new Promise((resolve, reject) => {

        commit('sm_waitHotelMappInfo', true)

        axios.post(window.url_api + 'api/Hotel/HotelMappInfo',
        filter
        , {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log(response.data.result)
                
                commit('sm_HotelMappInfo', response.data.result)
                resolve(response.data.result)
            })
            .catch(error => {
                console.log(error.response)
                commit('sm_waitHotelMappInfo', false)
                reject(new Error("Ошибка"));
            })
            .finally(() => {
        
                commit('sm_waitHotelMappInfo', false)
            })
    })
},

async sa_RepHotelMapStat({ commit },isDetail) {
    return new Promise((resolve, reject) => {

      commit('sm_waitRepHotelMapStat', true)
      commit('sm_RepHotelMapStatFileId', '')

      axios.get(window.url_api + 'api/Hotel/RepHotelMapStat?isDetail='+isDetail

        , {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          console.log(response.data.result)
          commit('sm_RepHotelMapStat', response.data.result)
          commit('sm_RepHotelMapStatFileId', response.data.fileID)
          resolve(response.data.result)
        })
        .catch(error => {
          console.log(error.response)
          commit('sm_waitRepHotelMapStat', false)
          reject(error);
        })
        .finally(() => {

          commit('sm_waitRepHotelMapStat', false)
        })
    })
  },

    },
    
    mutations: {
        sm_hotelsuppliersearch(state, response) {
            state.resulthotelsuppliersearch = response.data.result;
            state.resulthotelsupplierlastpage = response.data.lastPage;
            state.resulthotelsuppliertotalcount = response.data.totalCount;
        },
        sm_waithotelsuppliersearch(state, waithotelsuppliersearch) {
            state.waithotelsuppliersearch = waithotelsuppliersearch
        },
        sm_hotelsearch(state, response) {
            //  state.resulthotelsearch = resulthotelsearch
            state.resulthotelsearch = response.data.result;
            state.resulthotellastpage = response.data.lastPage;
            state.resulthoteltotalcount = response.data.totalCount;
        },
        sm_waithotelsearch(state, waithotelsearch) {
            state.waithotelsearch = waithotelsearch
        },

        sm_mappingHotels(state, resultmappingHotels) {
            state.resultmappingHotels = resultmappingHotels
        },

        sm_waitmappingHotels(state, waitmappingHotels) {
            state.waitmappingHotels = waitmappingHotels
        },

        sm_Categories(state, resultCategories) {
            state.resultCategories = resultCategories
        },

        sm_waitCategories(state, waitCategories) {
            state.waitCategories = waitCategories
        },

        sm_Suppliers(state, resultSuppliers) {
            state.resultSuppliers = resultSuppliers
        },

        sm_waitSuppliers(state, waitSuppliers) {
            state.waitSuppliers = waitSuppliers
        },

        sm_HotelAction(state, resultHotelAction) {
            state.resultHotelAction = resultHotelAction
        },

        sm_waitHotelAction(state, waitHotelAction) {
            state.waitHotelAction = waitHotelAction
        },

        sm_CreateParentHotel(state, resultCreateParentHotel) {
            state.resultCreateParentHotel = resultCreateParentHotel
        },

        sm_waitCreateParentHotel(state, waitCreateParentHotel) {
            state.waitCreateParentHotel = waitCreateParentHotel
        },

        sm_UnmappingHotelSupplier(state, resultUnmappingHotelSupplier) {
            state.resultUnmappingHotelSupplier = resultUnmappingHotelSupplier
        },

        sm_waitUnmappingHotelSupplier(state, waitUnmappingHotelSupplier) {
            state.waitUnmappingHotelSupplier = waitUnmappingHotelSupplier
        },
        sm_HotelDetail(state, resultHotelDetail) {
            state.resultHotelDetail = resultHotelDetail
        },

        sm_waitHotelDetail(state, waitHotelDetail) {
            state.waitHotelDetail = waitHotelDetail
        },

        sm_HotelSupplierAutoSearch(state, resultHotelSupplierAutoSearch) {
            state.resultHotelSupplierAutoSearch = resultHotelSupplierAutoSearch.data.result;
            state.resulthotelsupplierautolastpage = resultHotelSupplierAutoSearch.data.lastPage;
            state.resulthotelsupplierautototalcount = resultHotelSupplierAutoSearch.data.totalCount;
        },

        sm_waitHotelSupplierAutoSearch(state, waitHotelSupplierAutoSearch) {
            state.waitHotelSupplierAutoSearch = waitHotelSupplierAutoSearch
        },

        sm_MappingHotelsMass(state, resultMappingHotelsMass) {
            state.resultMappingHotelsMass = resultMappingHotelsMass
        },

        sm_waitMappingHotelsMass(state, waitMappingHotelsMass) {
            state.waitMappingHotelsMass = waitMappingHotelsMass
        },

        sm_HotelDetailSave(state, resultHotelDetailSave) {
            state.resultHotelDetailSave = resultHotelDetailSave
        },

        sm_waitHotelDetailSave(state, waitHotelDetailSave) {
            state.waitHotelDetailSave = waitHotelDetailSave
        },

        sm_HistoryObject(state, resultHistoryObject) {
            state.resultHistoryObject = resultHistoryObject
        },

        sm_waitHistoryObject(state, waitHistoryObject) {
            state.waitHistoryObject = waitHistoryObject
        },

        sm_HotelImageAdd(state, resultHotelImageAdd) {
            state.resultHotelImageAdd = resultHotelImageAdd
          },
          
        sm_waitHotelImageAdd(state, waitHotelImageAdd) {
            state.waitHotelImageAdd = waitHotelImageAdd
        },

        sm_HotelImageSetMain(state, resultHotelImageSetMain) {
            state.resultHotelImageSetMain = resultHotelImageSetMain
          },
      
          sm_waitHotelImageSetMain(state, waitHotelImageSetMain) {
            state.waitHotelImageSetMain = waitHotelImageSetMain
          },
  
          sm_HotelFactDetail(state, resultHotelFactDetail) {
            state.resultHotelFactDetail = resultHotelFactDetail
          },
      
          sm_waitHotelFactDetail(state, waitHotelFactDetail) {
            state.waitHotelFactDetail = waitHotelFactDetail
          },

          

        sm_HotelFactDetailSave(state, resultHotelFactDetailSave) {
            state.resultHotelFactDetailSave = resultHotelFactDetailSave
        },

        sm_waitHotelFactDetailSave(state, waitHotelFactDetailSave) {
            state.waitHotelFactDetailSave = waitHotelFactDetailSave
        },
        
        sm_HotelMappInfo(state, resultHotelMappInfo) {
            state.resultHotelMappInfo = resultHotelMappInfo
        },

        sm_waitHotelMappInfo(state, waitHotelMappInfo) {
            state.waitHotelMappInfo = waitHotelMappInfo
        },

        sm_RepHotelMapStat(state, resultRepHotelMapStat) {
            state.resultRepHotelMapStat = resultRepHotelMapStat
          },
      
          sm_waitRepHotelMapStat(state, waitRepHotelMapStat) {
            state.waitRepHotelMapStat = waitRepHotelMapStat
          },

            sm_RepHotelMapStatFileId(state, fileId) {
            state.resultRepHotelMapStatFileId = fileId
          },


    },
    state: {
        resulthotelsuppliersearch: [],
        resulthotelsupplierlastpage: 1,
        resulthotelsuppliertotalcount: 0,

        resulthotelsupplierautolastpage: 1,
        resulthotelsupplierautototalcount: 0,
        waithotelsuppliersearch: false,


        resulthotelsearch: [],
        resulthotellastpage: 1,
        resulthoteltotalcount: 0,

        waithotelsearch: false,

        resultmappingHotels: {},
        waitmappingHotels: false,

        resultCategories: [],
        waitCategories: false,

        resultSuppliers: [],
        waitSuppliers: false,

        resultHotelAction: false,
        waitHotelAction: false,

        resultCreateParentHotel: -1,
        waitCreateParentHotel: false,

        resultUnmappingHotelSupplier: false,
        waitUnmappingHotelSupplier: false,

        resultHotelSupplierAutoSearch: [],
        waitHotelSupplierAutoSearch: false,

        resultMappingHotelsMass: "",
        waitMappingHotelsMass: false,

        resultHotelDetail: {},
        waitHotelDetail: false,

        resultHotelDetailSave: {},
        waitHotelDetailSave: false,

        resultHistoryObject: [],
        waitHistoryObject: false,

        resultHotelImageAdd: "",
        waitHotelImageAdd: false,

        resultHotelImageSetMain: "",
        waitHotelImageSetMain: false,

        resultHotelFactDetail: {},
        waitHotelFactDetail: false,

        resultHotelFactDetailSave: false,
        waitHotelFactDetailSave: false,

        resultHotelMappInfo: [],
        waitHotelMappInfo: false,

        resultRepHotelMapStat: [],
        waitRepHotelMapStat: false,
        resultRepHotelMapStatFileId: '',

    },
    getters: {
        getresulthotelsuppliersearch: state => state.resulthotelsuppliersearch,
        getresulthotelsupplierlastpage: state => state.resulthotelsupplierlastpage,
        getresulthotelsuppliertotalcount: state => state.resulthotelsuppliertotalcount,

        getwaithotelsuppliersearch: state => state.waithotelsuppliersearch,
        getresulthotellastpage: state => state.resulthotellastpage,
        getresulthoteltotalcount: state => state.resulthoteltotalcount,


        getresulthotelsearch: state => state.resulthotelsearch,
        getwaithotelsearch: state => state.waithotelsearch,

        getresultmappingHotels: state => state.resultmappingHotels,
        getwaitmappingHotels: state => state.waitmappingHotels,


        getresultCategories: state => state.resultCategories,
        getwaitCategories: state => state.waitCategories,

        getresultSuppliers: state => state.resultSuppliers,
        getwaitSuppliers: state => state.waitSuppliers,

        getresultHotelAction: state => state.resultHotelAction,
        getwaitHotelAction: state => state.waitHotelAction,


        getresultCreateParentHotel: state => state.resultCreateParentHotel,
        getwaitCreateParentHotel: state => state.waitCreateParentHotel,

        getresultUnmappingHotelSupplier: state => state.resultUnmappingHotelSupplier,
        getwaitUnmappingHotelSupplier: state => state.waitUnmappingHotelSupplier,

        getresultHotelSupplierAutoSearch: state => state.resultHotelSupplierAutoSearch,
        getwaitHotelSupplierAutoSearch: state => state.waitHotelSupplierAutoSearch,
        getresulthotelsupplierautolastpage: state => state.resulthotelsupplierautolastpage,
        getresulthotelsupplierautototalcount: state => state.resulthotelsupplierautototalcount,

        getresultMappingHotelsMass: state => state.resultMappingHotelsMass,
        getwaitMappingHotelsMass: state => state.waitMappingHotelsMass,

        getresultHotelDetail: state => state.resultHotelDetail,
        getwaitHotelDetail: state => state.waitHotelDetail,

        getresultHotelDetailSave: state => state.resultHotelDetailSave,
        getwaitHotelDetailSave: state => state.waitHotelDetailSave,

        getresultHistoryObject: state => state.resultHistoryObject,
        getwaitHistoryObject: state => state.waitHistoryObject,

        getresultHotelImageAdd: state => state.resultHotelImageAdd,
        getwaitHotelImageAdd: state => state.waitwaitHotelImageAdd,

        getresultHotelImageSetMain: state => state.resultHotelImageSetMain,
        getwaitHotelImageSetMain: state => state.waitwaitHotelImageSetMain,

        getresultHotelFactDetail: state => state.resultHotelFactDetail,
        getwaitHotelFactDetail: state => state.waitwaitHotelFactDetail,

        getresultHotelFactDetailSave: state => state.resultHotelFactDetailSave,
        getwaitHotelFactDetailSave: state => state.waitHotelFactDetailSave,

        getresultHotelMappInfo: state => state.resultHotelMappInfo,
        getwaitHotelMappInfo: state => state.waitHotelMappInfo,

        getresultRepHotelMapStat: state => state.resultRepHotelMapStat,
        getwaitRepHotelMapStat: state => state.waitRepHotelMapStat,
        getresultRepHotelMapStatFileId: state => state.resultRepHotelMapStatFileId,
    }
}
