import axios from 'axios'

export default {
    actions: {
        async sa_regions({ commit }, filter) {
            return new Promise(() => {

                commit('sm_waitregions', { issup: filter.isSupp, value: true })

                axios.get(window.url_api + 'api/Region/Regions?countryId=' + filter.countryId + '&name=' + filter.name + '&regionID=' + filter.regionId+ '&isOnlyWarning=' + filter.isOnlyWarning, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        //console.log(response.data.result)
                        if (filter.isSupp)
                            commit('sm_regionssupplier', response.data.result);
                        else
                            commit('sm_regions', response.data.result);
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitregions', { issup: filter.isSupp, value: false })
                    })
                    .finally(() => {

                        commit('sm_waitregions', { issup: filter.isSupp, value: false })
                    })
            })
        },


    },
    mutations: {



        sm_regions(state, resultregions) {
            state.resultregions = resultregions
        },

        sm_regionssupplier(state, resultregions) {
            state.resultregionssupplier = resultregions
        },

        sm_waitregions(state, data) {

            if (data.issup)
                state.waitregionssupplier = data.value
            else
                state.waitregions = data.value
        },



    },
    state: {


        resultregions: [],
        resultregionssupplier: [],
        waitregions: false,
        waitregionssupplier: false,

    },
    getters: {


        getresultregions: state => state.resultregions,
        getresultregionssupplier: state => state.resultregionssupplier,
        getwaitregions: state => state.waitregions,
        gewaitregionssupplier: state => state.waitregionssupplier,
    }
}
