import axios from 'axios'

export default {
    actions: {
        async sa_mappUsers({ commit }) {
            return new Promise(() => {

                commit('sm_waitmappUsers', true)

                axios.get(window.url_api + 'api/MappReport/Users',
                 {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response.data.result)
                        commit('sm_mappUsers', response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitmappUsers', false)
                    })
                    .finally(() => {
                
                        commit('sm_waitmappUsers', false)
                    })
            })
        },
        async sa_MapReportData({ commit },filter) {
            return new Promise(() => {

                commit('sm_waitMapReportData', true)

                axios.post(window.url_api + 'api/MappReport/MapReportData',
                filter
                
                , {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response.data.result)
                        commit('sm_MapReportData', response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitMapReportData', false)
                    })
                    .finally(() => {
                
                        commit('sm_waitMapReportData', false)
                    })
            })
        },


    },
    mutations: {



        sm_mappUsers(state, resultmappUsers) {
            state.resultmappUsers = resultmappUsers
        },

        sm_waitmappUsers(state, waitmappUsers) {
            state.waitmappUsers = waitmappUsers
        },

        sm_MapReportData(state, resultMapReportData) {
            state.resultMapReportData = resultMapReportData
        },

        sm_waitMapReportData(state, waitMapReportData) {
            state.waitMapReportData = waitMapReportData
        },



    },
    state: {


        resultmappUsers: [],
        waitmappUsers: false,
        resultMapReportData: [],
        waitMapReportData: false,

    },
    getters: {


        getresultmappUsers: state => state.resultmappUsers,
        getwaitmappUsers: state => state.waitwaitmappUsers,

        getresultMapReportData: state => state.resultMapReportData,
        getwaitMapReportData: state => state.waitwaitMapReportData,
    }
}
