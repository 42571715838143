import axios from 'axios'

export default {
    actions: {
        async sa_OWSearch({ commit },filter) {
            return new Promise((resolve, reject) => {

                commit('sm_waitOWSearch', true)

                axios.post(window.url_api + 'api/ObjectWarning/OWSearch',
                filter
                , {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response.data.result)
						
                        commit('sm_OWSearch', response)
						resolve(response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitOWSearch', false)
						reject(new Error("Ошибка"));
                    })
                    .finally(() => {
                
                        commit('sm_waitOWSearch', false)
                    })
            })
        },

        async sa_OWAction({ commit },filter) {
            return new Promise((resolve, reject) => {

                commit('sm_waitOWAction', true)

                axios.post(window.url_api + 'api/ObjectWarning/Action',
                filter
                , {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response.data.result)
						
                        commit('sm_OWAction', response.data.result)
						resolve(response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitOWAction', false)
						reject(new Error("Ошибка"));
                    })
                    .finally(() => {
                
                        commit('sm_waitOWAction', false)
                    })
            })
        },



    },
    mutations: {



        sm_OWSearch(state, response) {
            state.resultOWSearch = response.data.result;
            state.resultowlastpage = response.data.lastPage;
            state.resultowtotalcount = response.data.totalCount;

        },

        sm_waitOWSearch(state, waitOWSearch) {
            state.waitOWSearch = waitOWSearch
        },

        sm_OWAction(state, resultOWAction) {
            state.resultOWAction = resultOWAction
        },

        sm_waitOWAction(state, waitOWAction) {
            state.waitOWAction = waitOWAction
        },




    },
    state: {


        resultOWSearch: [],
        waitOWSearch: false,
        resultowlastpage: 1,
        resultowtotalcount: 0,

        resultOWAction: false,
        waitOWAction: false,

    },
    getters: {


        getresultOWSearch: state => state.resultOWSearch,
        getwaitOWSearch: state => state.waitOWSearch,
        getresultOWlastpage: state => state.resultowlastpage,
        getresultOWtotalcount: state => state.resultowtotalcount,


        getresultOWAction: state => state.resultOWAction,
        getwaitOWAction: state => state.waitOWAction,
    }
}
