import axios from 'axios'

export default {
    actions: {
        async sa_countrysearch({ commit }, countrysearch) {
            return new Promise(() => {

                commit('sm_waitcountrysearch', true)

                axios.post(window.url_api + 'api/Country/Search', countrysearch, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response.data.result)

                            commit('sm_countrysearch', response.data.result)

                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitcountrysearch', false)
                    })
                    .finally(() => {
                        // commit('sm_countrysearchsLoading', false)
                        //  commit('sm_waitcountrysearch', false)
                        commit('sm_waitcountrysearch', false)
                    })
            })
        },

        async sa_countries({ commit }) {
            return new Promise(() => {

                commit('sm_waitcountries', true)

                axios.get(window.url_api + 'api/Country/Countries', {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response.data.result)
                        commit('sm_countries', response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitcountries', false)
                    })
                    .finally(() => {
                
                        commit('sm_waitcountries', false)
                    })
            })
        },


    },
    mutations: {

        sm_countrysearch(state, resultcountrysearch) {
            state.resultcountrysearch = resultcountrysearch
        },

        sm_waitcountrysearch(state, waitcountrysearch) {
            state.waitcountrysearch = waitcountrysearch
        },


        sm_countries(state, resultcountries) {
            state.resultcountries = resultcountries
        },

        sm_waitcountries(state, waitcountries) {
            state.waitcountries = waitcountries
        },



    },
    state: {

        resultcountrysearch: [],
        waitcountrysearch: false,

        resultcountries: [],
        waitcountries: false,

    },
    getters: {

        getresultcountrysearch: state => state.resultcountrysearch,
        getwaitcountrysearch: state => state.waitcountrysearch,

        getresultcountries: state => state.resultcountries,
        gewaitcountries: state => state.waitwaitcountries,
    }
}
